import { makeStyles } from '@material-ui/core';
import ArrowBackwardIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Swipe } from 'react-swipe-component';
import { GLOBAL_PARS } from '../../../globals';
import { getUpdateBullets, onEndEventTimeout, onUpdateNextInterval } from '../../../socketAPI';
import * as actions from '../../../store/actions';
import HexagonInput from '../../HexagonInput/HexagonInput';
import SwipeDrawer from '../../SwipeDrawer/SwipeDrawer';

const useStyles = makeStyles((theme) => ({
  test: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  arrowsSwipe: {
    display: 'flex', 
    justifyContent: 'space-between', 
    width: '100%', 
    padding: '0 30px',
  }
}));

const MobileAudienceClient = () => {

  const dispatch = useDispatch();
  const classes = useStyles();

  const value = useSelector(st => st.settingsReducer.tab);
  // const [progress, setProgress] = useState(0);

  const initialActive = {first: [false, false, false],
    second: [false, false, false]};

  const [active, setActive] = useState(initialActive);

  const setAllRatios = useCallback(
    data => {
      dispatch(actions.setAllRatios(data));
    },
    [dispatch],
  );

  const setTab = useCallback(
    data => {
      dispatch(actions.setTab(data));
    },
    [dispatch],
  );

  const onSwipeLeftListener = (event) => {
    const val = Math.abs((value - 1) % 2)
    setTab(val);
  }

  const onSwipeRightListener = (event) => {
    setTab((value + 1) % 2);
  }

  useEffect(() => {
    getUpdateBullets((err, data) => {
      setActive(data.data);
    })

    onUpdateNextInterval((err, data) => {
      setAllRatios(data.ratios);
      setActive(data.active);
      if (checkActive(data.active['first'])) {
        setTab(1);
      } else {
        setTab(0);
      }

      // const timer = setInterval(() => {
      //   setProgress((oldProgress) => {
      //     if (oldProgress === 100) {
      //       return 0;
      //     }
      //     const diff = Math.random() * 10;
      //     return Math.min(oldProgress + diff, 100);
      //   });
      // }, 29800);
      //navigator.vibrate(200);
    });

    onEndEventTimeout((err) => {
      setActive(initialActive)
    });
    
  }, [] );// eslint-disable-line react-hooks/exhaustive-deps
  
  const checkActive = actives => actives.every(a => !a);

  return (
    <div>
      {/* <Box display="flex" style={{position: 'absolute', top: '15px', right: '15px', zIndex: '2'}}>
        <IconButton onClick={() => setOpenSettings(true)} aria-label="open settings">
          <TuneIcon color='primary' />
        </IconButton>
      </Box> */}
      <SwipeDrawer />
      {/* <LinearProgress variant="determinate" value={progress} /> */}
      {
        value === 0 ?
        <HexagonInput idx='first' actives={active['first']} /> :
        <HexagonInput idx='second' actives={active['second']} />
      }
      <Swipe
        nodeName="div"
        className={classes.test}
        style={{height: GLOBAL_PARS.HEIGHT_SWIPE}}
        onSwipedLeft={onSwipeLeftListener}
        onSwipedRight={onSwipeRightListener}
        detectTouch={true}
      > 
      {
        !checkActive(active['first']) && !checkActive(active['second']) ?
        <div className={classes.arrowsSwipe}>
          <ArrowBackwardIosIcon fontSize='large' onClick={onSwipeLeftListener} />
          <ArrowForwardIosIcon fontSize='large' onClick={onSwipeRightListener} />
        </div> : null
      }
      </Swipe>
    </div>
  );
}


export default MobileAudienceClient;