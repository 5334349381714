import React from "react";
import { View } from "react-native";
import { GLOBAL_PARS } from '../../globals';
import Bullet from './Bullet';
import HexagonSvg from "./HexagonSvg";

  const HexagonInput = ({
    idx,
    actives
}: Props) => {

    return (
      <>
        <View key="hexagon" style={{width: GLOBAL_PARS.WIDTH, height: GLOBAL_PARS.HEIGHT - GLOBAL_PARS.HEIGHT_SWIPE}}>
            <HexagonSvg chart={idx} />
            {
              actives.map((el, index) => (
                el ? <Bullet key={index} chart={idx} nPoint={index} /> : null
              ))
            }
        </View>
      </>
    )
  }

  export default HexagonInput;